import {
    CLIENT_FEEDBACK_REQUEST,
    CLIENT_FEEDBACK_SUCCESS,
    CLIENT_FEEDBACK_FAILURE,
  } from "../../actions/types";
  
  // reducer.js
  const initialState = {
    isLoading: false,
    response: null,
    error: null,
  };
  
  export const ClientFeedbackReducer = (state = initialState, action) => {
    switch (action.type) {
      case CLIENT_FEEDBACK_REQUEST:
        return { ...state, isLoading: true, response: null, error: null };
      case CLIENT_FEEDBACK_SUCCESS:
        return {
          ...state,
          isLoading: false,
          response: action.payload,
          error: null,
        };
      case CLIENT_FEEDBACK_FAILURE:
        return {
          ...state,
          isLoading: false,
          response: null,
          error: action.payload,
        };
      default:
        return state;
    }
  };
  